<template>
	<div class="container">
		<div class="loader" v-if="isLoading">
			loading...
		</div>

		<div class="w-100" v-if="!isLoading">
			<div class="row header align-end">
				<div class="col-6">
					<a class="text-brand font-weight-bold text-decoration-none" @click="goTo('Courses')"><v-icon class="text-brand">mdi-chevron-left</v-icon> <u>Back</u></a>
					<h3 class="mt-5">{{ courseTitle }}</h3>
				</div>
				<div class="col-6 text-right">
					<a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline" @click="goTo('Quizes')">Quiz</a> <a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline ml-5" @click="goTo('CourseSettings')">Assign</a>
				</div>

				<div class="col-4 course-meta">
					<table class="table table-borderless table-no-padding">
						<tbody>
							<tr>
								<td class="label">Created By:</td>
								<td class="text-left">Anna Martinez</td>
							</tr>
							<tr>
								<td class="label">Last Edited:</td>
								<td class="text-left">{{ lastUpdate }}</td>
							</tr>
							<tr>
								<td class="label">Date Created:</td>
								<td class="text-left">{{ createdDate }}</td>
							</tr>
							<tr>
								<td class="label">Status:</td>
								<td class="text-left">{{ courseStatus }}</td>
							</tr>
							<tr><td></td><td></td></tr>
							<!-- <tr>
								<td class="label">Assigned Quiz:</td>
								<td class="text-left"></td>
							</tr> -->
						</tbody>
					</table>
				</div>
			</div>

			<div class="row stat-cards" v-if="topStats">
				<div class="col">
					<div class="stat-container border-brand">
						<span class="w-100 d-block stat-name">Total User Assigned</span>
						<span class="d-block stat-num">{{ topStats.assigned_users ?? 0 }}</span>
					</div>
				</div>
				<div class="col">
					<div class="stat-container border-brand">
						<span class="w-100 d-block stat-name">Currently Taking</span>
						<span class="d-block stat-num">{{ topStats.num_users_in_progress ?? 0 }}</span>
					</div>
				</div>
				<div class="col">
					<div class="stat-container border-green">
						<span class="w-100 d-block stat-name">Passed</span>
						<span class="d-block stat-num">{{ topStats.passes ?? 0 }}</span>
					</div>
				</div>
				<div class="col">
					<div class="stat-container border-red">
						<span class="w-100 d-block stat-name">Failed</span>
						<span class="d-block stat-num">{{ topStats.fails ?? 0 }}</span>
					</div>
				</div>
				<div class="col">
					<div class="stat-container border-brand">
						<span class="w-100 d-block stat-name">Approval Rate</span>
						<span class="d-block stat-num">{{ topStats.approval_rates ?? '0%' }}</span>
					</div>
				</div>
			</div>

			<div class="row brand-tabs mt-10">

				<v-tabs
					v-model="currentItem"
					ligth
					background-color="transparent"
					:slider-color="$store.getters.theme.mainAccentColor"
					slider-size="8"
					color="#000"
					class="brand-tabs-container"
				>
					<v-tab
						v-for="(tab, index) in tabs"
						:key="index"
						:href="`#${tab.component}`"
						class="organization-settings-tab-button primary-text"
						@click="currentItem = tab.component"
					>
						{{ tab.title }}
					</v-tab>
				</v-tabs>

				<v-tabs-items
					v-model="currentItem"
					:loading="isLoading"
				>
					<v-tab-item
						v-for="tab in tabs"
						:key="tab.title"
						:value="tab.component"
						class="organization-settings-tab"
					>
						<keep-alive>
							<component
								:is="tab.component"
								:allUsers="allUsers"
								:takingUsers="takingUsers"
								:pendingUsers="pendingUsers"
								:passesUsers="passesUsers"
								:failUsers="failUsers"
								@getListUsersAssigned="getListUsersAssigned"
							/>
						</keep-alive>
					</v-tab-item>
				</v-tabs-items>

			</div>
		</div>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col
				><admin-default-header
					>{{ courseTitle }}
				</admin-default-header></v-col
			>
		</v-row>
		<v-row>
			<v-col>
				<admin-default-description>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit.
					Ipsam maiores vero quo nemo accusantium quae delectus a illo
					laudantium pariatur, soluta velit repellendus iusto deserunt
					perferendis odit, facere, magni veniam.
				</admin-default-description></v-col
			>
			<v-col class="d-flex flex-row align-start justify-end">
				<v-btn
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					@click="goTo('CourseEditor')"
					>Edit Course</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					@click="goTo('Quizes')"
					>Quiz</v-btn
				>
				<v-btn
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					@click="goTo('CourseSettings')"
					>Assign</v-btn
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<span class="mr-4">Created by: User name</span>
				<span class="mr-4"
					>Last edited: {{ lastUpdate }} day<span
						v-if="lastUpdate > 1"
						>s</span
					>
					ago</span
				>
				<span class="mr-4">Date created: {{ createdDate }} </span>
				<span class="mr-4">Status: {{ courseStatus }}</span>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-row">
				<p class="mr-3">Quiz assigned</p>
				<v-chip class="mr-3">{{ assignedQuizName }}</v-chip>
				<span class="mr-3">Change quiz assigned</span>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<article class="d-flex flex-row justify-space-between">
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Total User Assigned</admin-default-subheader
						><admin-default-header>{{
							totalUserAsigned
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Currently Taking</admin-default-subheader
						><admin-default-header>{{
							currentlyTaking
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader>Passes</admin-default-subheader
						><admin-default-header>{{
							passes
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Failures</admin-default-subheader
						><admin-default-header>{{
							failures
						}}</admin-default-header>
					</figure>
					<figure class="d-flex flex-column align-start">
						<admin-default-subheader
							>Approval Rate</admin-default-subheader
						><admin-default-header>{{
							approvalRate
						}}</admin-default-header>
					</figure>
				</article>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-tabs
					v-model="currentItem"
					ligth
					background-color="transparent"
					:slider-color="$store.getters.theme.mainAccentColor"
					slider-size="8"
					color="#000"
					class="organization-settings-tab-buttons-container"
				>
					<v-tab
						v-for="(tab, index) in tabs"
						:key="index"
						:href="`#${tab.component}`"
						class="organization-settings-tab-button primary-text"
						@click="currentItem = tab.component"
					>
						{{ tab.title }}
					</v-tab>
				</v-tabs>

				<v-tabs-items
					v-model="currentItem"
					class="organization-settings-tabs-container"
				>
					<v-tab-item
						v-for="tab in tabs"
						:key="tab.title"
						:value="tab.component"
						class="organization-settings-tab"
					>
						<keep-alive>
							<component
								:is="tab.component"
								:allUsers="allUsers"
								:takingUsers="takingUsers"
								:pendingUsers="pendingUsers"
								:passesUsers="passesUsers"
								:failUsers="failUsers"
								@getListUsersAssigned="getListUsersAssigned"
							/>
						</keep-alive>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";
import CourseAnalyticsAll from "./CourseAnalyticsAll.vue";
import CourseAnalyticsFails from "./CourseAnalyticsFails.vue";
import CourseAnalyticsNotStarted from "./CourseAnalyticsNotStarted.vue";
import CourseAnalyticsPasses from "./CourseAnalyticsPasses.vue";
import CourseAnalyticsTaking from "./CourseAnalyticsTaking.vue";

export default {
	name: "CourseAnalytics",
	components: {
		CourseAnalyticsAll,
		CourseAnalyticsFails,
		CourseAnalyticsNotStarted,
		CourseAnalyticsPasses,
		CourseAnalyticsTaking
	},
	computed: {
		...mapGetters(["isLoading"]),
		totalUserAsigned() {
			let stat = 0
			if (this.topStats != null) {
				stat = this.topStats.assigned_users
			}
			return stat
		},
		currentlyTaking() {
			let stat = 0
			if (this.topStats != null) {
				stat = this.topStats.num_users_in_progress
			}
			return stat
		},
		passes() {
			let stat = 0
			if (this.topStats != null) {
				stat = this.topStats.passes
			}
			return stat
		},
		failures() {
			if (this.topStats === null) return;
			return this.topStats.fails;
		},
		approvalRate() {
			if (this.topStats === null) return;
			return this.topStats.approval_rates;
		},
		assignedQuizName() {
			if (!!this.course === null) return;
			return this.course?.quizzes[0]?.quiz?.name;
		},
		courseTitle() {
			if (!!this.course === null) return;
			return this.course?.title;
		},
		courseStatus() {
			if (!!this.course === null) return;
			return this.course?.published_at === null ? "Draft" : "Published";
		},
		lastUpdate() {
			if (!!this.course === null) return;
			return Math.ceil(
				Number(new Date() - new Date(this.course?.updated_at)) /
					1000 /
					60 /
					60 /
					24
			);
		},
		createdDate() {
			if (!!this.course === null) return;
			return new Date(
				this.course?.created_at.slice(0, 10)
			).toLocaleDateString();
		}
	},
	data() {
		return {
			tabs: [
				{ title: "All", component: "CourseAnalyticsAll" },
				{ title: "Fails", component: "CourseAnalyticsFails" },
				{
					title: "Not Started",
					component: "CourseAnalyticsNotStarted"
				},
				{ title: "Passes", component: "CourseAnalyticsPasses" },
				{ title: "Taking", component: "CourseAnalyticsTaking" }
			],
			currentItem: "",
			course: null,
			topStats: null,
			allUsers: null,
			takingUsers: null,
			pendingUsers: null,
			passesUsers: null,
			failUsers: null
		};
	},
	methods: {
		goTo(val) {
			const additionalParameters =
				val !== "Quizes" ? { id: this.$route.params.id } : null;
			this.$router.push({
				name: val,
				...additionalParameters
			});
		},
		async getTopStats(id) {
			const {
				data: { data }
			} = await get(`/course_analytics/${id}`);
			this.topStats = data;
		},
		async getCourseData(id) {
			const {
				data: { data }
			} = await get(`/course/${id}`);
			this.course = data;
		},
		async getListUsersAssigned(scope = "") {
			const {
				data: { data }
			} = await get(`/student_courses_analytics?${scope}`);
			return data;
		}
	},
	async created() {
		await this.$store.dispatch("setIsLoading", true);
		this.allUsers = await this.getListUsersAssigned();
		this.takingUsers = await this.getListUsersAssigned("taking");
		this.pendingUsers = await this.getListUsersAssigned("pending");
		this.passesUsers = await this.getListUsersAssigned("passes");
		this.failUsers = await this.getListUsersAssigned("fails");
		await this.getCourseData(this.$route.params.id);
		await this.getTopStats(this.$route.params.id);
		await this.$store.dispatch("setIsLoading", false);
	}
};
</script>

<style></style>
